import { ClientComponent } from "js-client-widget";
//import { ClientComponent } from "... my local path to ... \\client-widget-bootstrap\\src\\index.js";


class Hapidam extends ClientComponent {

  constructor(paramOrToken) {
    // retro-compatibility
    var param = typeof paramOrToken === "object" ? paramOrToken : { token: paramOrToken };

    // call parent constructor
    super(param)

    // set custom parameters
    this.token = param.token || null;
    this.baseURL = param.baseURL !== undefined ? param.baseURL : process.env.ENV_WWW_ENTRYPOINT;

    // internal select observer to trigger custom handling for CkEditor
    this.on('select', this.__ckEditorSelect.bind(this));
  }

  open(options = {}) {
    if (!this.token) {
      throw 'Hapidam.open invalid Token';
      return this.fire('error', { 'message': 'invalid token' })
    }

    // options format detection for retro-compatibility
    if (typeof options === "boolean")
      options = options ? { format: 'json' } : {};

    var widgetOptions = {
      url: `${this.baseURL}/widget/${this.token}${this.__encodeQueryData(options)}`,
      height: window.screen.height * 0.8 > 1080 ? 1080 : window.screen.height * 0.8,
      width: window.screen.width * 0.8 > 1500 ? 1500 : window.screen.width * 0.8,
    };
    // Calling the original super class open function
    // here we can call super(...) instead
    // but for more coherency with the alternative open function
    // I choose to call ___open(...)
    this.___open(widgetOptions)
  }

  openBuilder(data = null, options = {}) {
    if (!this.token) {
      throw 'Hapidam.open invalid Token';
      return this.fire('error', { 'message': 'invalid token' })
    }

    var widgetOptions = {
      url: `${this.baseURL}/widget-builder/${this.token}${this.__encodeQueryData(options)}`,
      height: window.screen.height * 0.8 > 1080 ? 1080 : window.screen.height * 0.8,
      width: window.screen.width * 0.8 > 1500 ? 1500 : window.screen.width * 0.8,
    };
    // Calling the original super class open function
    // and set data to be posted when widget is ready
    this.___open(widgetOptions, data)
  }

  connect() {
    var widgetOptions = {
      url: `${this.baseURL}/widget-connect`,
      height: window.screen.height * 0.8 > 740 ? 740 : window.screen.height * 0.8,
      width: window.screen.width * 0.8 > 600 ? 600 : window.screen.width * 0.8,
    };
    // Calling the original super class open function
    this.___open(widgetOptions)
  }

  initCKEditor() {
    // simply return the widget url that would be use as ckeditor setting
    // this custom url contain the client ID needed to ensure the communication with the widget
    return `${this.baseURL}/widget/${this.token}?hapidamClientId=${this.getId()}`;
  }

  setToken(token) {
    this.token = token
  }

  // url query safe encoding
  __encodeQueryData(data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.length ? '?' + ret.join('&') : '';
  }
  // ckeditor custom response listener
  __ckEditorSelect(data) {
    data = data || {};
    if (data.funcNum) {
      let selectedFormat = null;
      if (data.type === 'image') {
        selectedFormat = data.selectedFormat || data.display.native['lg']
      }
      if (typeof window?.CKEDITOR?.tools?.callFunction === 'function')
        window.CKEDITOR.tools.callFunction(data.funcNum, data.type === 'image' ? selectedFormat : data.display.original);
    }
  }
}
window.Hapidam = Hapidam;